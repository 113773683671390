import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Header from './components/header';
import Main from './pages/main';
import History from './pages/history';
import Gallery from './pages/gallery';
import './App.css';

function App(props) {
  return (
    <div className="App">
      <Router>
        <Header/>
        <Switch>
          <Route exact path = "/">
            <Main/>
          </Route>
          <Route path = "/history">
            <History/>
          </Route>
          <Route path = "/memories">
            <Gallery/>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
