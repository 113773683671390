import React, { Component } from 'react';
import GalleryHeader from '../components/galleryHeader';
import '../styles/gallery.css';
import media from '../util/img.json';


class Gallery extends Component{
    constructor(props){
        super(props);
        this.state = {
            openImage: false
        }
        this.imgRef = [];
        this.setRef = Element =>{
            this.imgRef = [].concat(this.imgRef, Element);
        }
    }

    handleClick = e => {
        this.setState({openImage: true});
        console.log(this.imgRef);
    }

    render(){
        return(
            <div>
                <GalleryHeader/>
                    <div className="photos-container">
                    {
                        media.img.map((data) => {
                            return(
                                <img
                                src = {`${process.env.PUBLIC_URL}/media/${data}`} 
                                className="photo"
                                alt={data}
                                key = {data}
                                onClick = {this.handleClick}
                                ref = {this.setRef}
                                />
                            )
                        })
                    }
                    </div>                    
            </div>
        )
    }
}

export default Gallery;