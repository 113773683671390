import React, {useState} from 'react';
import '../styles/header.css';
import {Link} from 'react-router-dom';

function Header(){
    let [mobil, setMobil] = useState(false);
    let [menu, setMenu] = useState(false);

    if(menu === true){
        setMobil(mobil = !mobil);
        setMenu(menu = !menu);
    }
 
    return(
        <div className = "header-container" id="header">
            <div className="logo">
                {/* <img alt="Nuestro logo" src={Logo}/> */}
               <Link to = "./"><h1>Diana y Angel</h1></Link>
            </div>
            <button 
            className = {`btn-menu-mobil ${mobil? "close" : false}`}
            onClick = {() => setMobil(mobil = !mobil)}> 
                {mobil? "X" : "Menú"} 
            </button>
            <div className = {`menu menu-mobil ${mobil? "active": false}`} onClick = {() => setMenu(menu = !menu)}>
                <div className="menu-container" onClick = {() => setMenu(menu = !menu)}>
                    <Link to = "/" onClick = {() => setMenu(menu = !menu)} >Home</Link>
                    <Link to = "/history"  onClick = {() => setMenu(menu = !menu)}>Nuestra historia</Link>
                    <Link to = "/memories" onClick = {() => setMenu(menu = !menu)}>Recuerdos</Link>
                    <Link to = "/secret"   onClick = {() => setMenu(menu = !menu)}>Un secreto</Link>
                </div>
                <h1 className = {`header-menu-mobil ${mobil? "active" : false}`}>"...Love was made for me and you..."</h1>
            </div>
        </div>
    )
}

export default Header;