import React, {useState, useEffect} from 'react';
import Image from '../util/paisaje-arboles-montanas-fondo-abstracto_28616-111.jpg';
import '../styles/history.css';
import AOS from 'aos';
import "aos/dist/aos.css";
import Juno from '../util/juno.svg';
import Saturn from '../util/saturn.svg';

function History(){

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      });

    return(
        <div className="history-container">
            <div 
            className = "image-history"
            style={{ backgroundImage: `url(${Image})` }}>
                <div className="title-history-page">
                    <h1>Our story.</h1>
                    <div className="subs">
                        <h3 className="left">Il était une fois</h3>
                        <h3 className="right">C'era una volta</h3>
                    </div>
                </div>
            </div>
            <div className = "text-history-container">
                <h2 data-aos="fade-up" 
                data-aos-anchor-placement="bottom-bottom">Un comienzo</h2>
                <span>
<p data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
... recuerdo perfectamente aquel día, el día que, como si fuera por arte de magia, 
descubrí que una chica me gustaba. Una chica de tez canela, estatura mediana, cabello ondulado, 
de mirada cautivadora, con un cuerpo de maravilla, con curvas enloquecedoras y por si fuera poco, 
con una mente brillante. Ese día, al verla descubrí en ella una chispa peculiar, la notaba más que 
antes, no era la misma chica que había conocido, se veía diferente, con mucha energía, más cálida, 
con un magnetismo que no había visto en nadie, iba vestida casual, con pantalón de mezclilla y blusa
blanca.
</p>
<br/>
<p data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
Aquel día durante el regreso a casa en el trasporte público no pude dejar de pensar en ella.
¿Qué había pasado?, ¿Se hizo algo diferente?, ¿o era yo alucinando?. Cual sea que haya sido la 
razón no importaba, a partir de ese día, me haría un fan secreto de aquella hermosura de mujer.
Era un reto, era mi amiga, convivía con ella, pasábamos tardes juntos, salíamos entre amigos.
Y no sabía si ella quería algo conmigo. Honestamente, no quería dar un paso en falso, ella poco
a poco se convirtió en alguien muy especial para mi, aunque no me quedaba del todo claro si era 
recíproco. 
</p>
<br/>
<p data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
Recuerdo perfectamente que conforme pasaban las semanas, la notaba cada vez más; incluso cuando la 
veía con otros hombres sentía envidía, jamás se lo hice saber, aunque ella notaba que sucedía algo extraño. 
No se imaginaba que sucedía, eso me queda claro...
</p>
<p data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
<br/>
...Un día entre amigos decidimos hacer una reunión, hacer carne asada, convivir y beber un poco de alcohol, esto 
lo hicimos en la casa de un compañero de clase, y fue aquel día en donde comenzó todo. Siendo honesto, aquel día ni 
un beso nos dimos, pero supe que ella sentía algo por mi, supe que era recíproco aunque confuso...
<br/>
... Había ido al jardín de la privada en donde vivía mi amigo, había sido una noche confusa, me quedaba claro que 
está mujer bonita no quería estar conmigo, no le atraía, ella estaba interesada en alguien más. Estando en el jardín 
me encontré con está linda chica, estaba ahí sola. Toda la noche había estado acompañada, y en ese momento, por fin, 
podía estar con ella a solas. Sin decirnos mucho nos fuimos acomodando, en aquel jardín acompañados, a lo lejos, por 
un amigo y su novia.
</p>
<br/>
<p data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
Perdí la noción del tiempo, no sé cuánto estuvimos ahí, pudo ser fácilmente una vida y no lo noté. Estuvimos 
platicando de cosas aleatorias, que no representaban nada realmente.
En algún movimiento que hicimos, ella se recostó quedando su cabeza sobre mi muslo, quedando sus labios 
expuestos para besarla, era mi oportunidad. No sabía si ella quería que la besara, pero sin lugar a duda,
yo moría por hacerlo, era lo único que quería. Sentía mariposas en el estómago, tenía el pulso acelerado, 
me costaba respirar tranquilamente, me temblaban las manos, estaba nervioso. Pero decidí irme acercando 
lentamente, ella no notaba que me estaba acercando a su boca, tal vez lo notó, pero no hizo nada para impedirlo, 
así que proseguí. </p>
<p data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">Cuando por fin estuvo lo 
suficientemente cerca de su rostro, moría de nervios. Jamás me 
había pasado a tal grado con una chica. Si hubiese sido otra mujer, probablemente la hubiese besado desde 
la tarde de ese día. Pero con ella era diferente, ella imponía respeto, era una mujer muy hermosa, quién 
jamás me había dado una "señal".
Así que decidí no besarla. sólo tome su frente y le plante un beso tierno, delicado. Pasado un 
tiempo, cambiamos de posición, y fui yo quién se recostó, quedando de una forma semejante. 
Y ella hizo algo muy similar a lo que yo había hecho, se acerco suavemente hacia mi, sin decir 
nada, y me plantó un beso en la frente, quedándonos recostado, ella recostada sobre mi pecho, 
y yo sobre su pierna. 
</p> 
<br/>
<p data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
No dijimos nada aquella noche, pero sabía que había algo. Esa noche sirvió para despejar algunas 
dudas, aunque todavía confundido, estaba decidido a ir más allá. Y por fin besarla...
Y ese fue el comienzo de una relación que lleva 4 años al día de hoy. 
</p>
<br/>
<p data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
Para mi Honey Bunny, chica hermosa, mujer bonita.
</p>
<br/>
<br/>
<p className="text-footer" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">Te amo, Diana.</p>
                </span>
            </div>
            <Planets/>
        </div>
    )
}

export default History;



function Planets(props){
    let [orbit, setOrbit] = useState(true);
    
    setTimeout(()=>{
        setOrbit(orbit = !orbit);
    }, 4000)

    return(
        <div className = "planets-container">
            <img src = {Juno} alt="juno" className={`juno ${orbit? "orbita-cool" : "false"}`}/>
            <img src = {Saturn} alt="saturn" className={`saturn ${orbit? "false" : "orbita-cool"}`}/>
        </div>
    )
}

