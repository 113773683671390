import React, {useState} from 'react';
import Juno from '../util/juno.svg';
import Saturn from '../util/saturn.svg';
import '../styles/main.css';



function Main(props){
    let [orbit, setOrbit] = useState(true);
    
    setTimeout(()=>{
        setOrbit(orbit = !orbit);
    }, 8200)

    return(
        <div className="main-container">
            <h1 className="main-title">Two in<br/> Love <br/>Can<br/>Make it</h1>
            <div className = "planets-container">
                <img src = {Juno} alt="juno" className={`juno ${orbit? "orbita-cool" : "false"}`}/>
                <img src = {Saturn} alt="saturn" className={`saturn ${orbit? "false" : "orbita-cool"}`}/>
            </div>
        </div>
    )
}

export default Main;