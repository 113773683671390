import React from 'react';
import Logo from '../util/nature-background-of-mountains-sunset-landscape-background-and-silhouette-of-forest-vector-illustration.jpg';
import '../styles/gallery-header.css';

function GalleryHeader(){
    return(
        <div>
            <div className="gallery-header">
                <div className="gallery-profile">
                    <img src={Logo} alt="profile"></img>
                </div>
                <div className="description">
                    <h1>Nosotros</h1>
                    <h2 className="mobil">Diana&Angel</h2>
                    <span className="profit mobil" role="img" aria-label="a heart">
                        Sin lugar a duda, hemos vivido todo tipo de experiencias. Cada una de ellas ha sido 
                        increíble a tu lado.💖  <br/>
                        Nos encanta viajar. ✈<br/> Amamos la ciencia y el conocimiento.📚 <br/>
                        Siempre soñamos en grande...💡
                    </span>
                </div>
            </div>
           <div className="description-mobil">
                <h2 className="mobil mobil-s">Diana&Angel</h2>
                <span className="profit mobil mobil-s" role="img" aria-label="a heart">
                    Sin lugar a duda, hemos vivido todo tipo de experiencias. Cada una de ellas ha sido 
                    increíble a tu lado.💖
                    <br/>Nos encanta viajar. ✈<br/> Amamos la ciencia y el conocimiento.📚 <br/>
                    Siempre soñamos en grande...💡
                </span>
           </div>
            <hr/>
        </div>
    )
}

export default GalleryHeader;